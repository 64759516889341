/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import "core-js/stable";
import "regenerator-runtime/runtime";
import * as React from "react";
// @ts-expect-error
globalThis.React = React;
